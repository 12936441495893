import React from "react"
import Layout from '../../components/layout'
import { Container, Header, Grid} from 'semantic-ui-react';

const ContactPage = () =>{
    return(
        <Layout page="internal">
            <Container>
                <Header as="h1">Design</Header>

                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                        <p><strong>Avoid waste through effective design processes</strong><br />
                        With increased popularity of user experience design, implementing an effective process seems to be the obvious. This is not always the case. Design can sometimes be treated as a passive activity within some organizations with little effort allocated to adopting a proven process for the product team. Many projects have time, scope, and cost constraints and lack of a proven, particularly a user-centered, can lead to exceeding the estimated time for design and development. A process such as Lean UX is known to be cost-effective by saving designers and developers time through assumptions validation.  Other processes such as user experience design and design thinking save designers from delivering a product that are not viable. For a team to be effective a proven process is required to reduce design and development time. 
                        </p>

                        </Grid.Column>
                        <Grid.Column>
            


                        </Grid.Column>
                    </Grid.Row>

                </Grid>
                <Grid columns={4}>
                    <Grid.Row>
                        <Grid.Column>
                            Brand Design and Development 
                        </Grid.Column>
                        <Grid.Column>
                            Digital Product Design
                        </Grid.Column>
                        <Grid.Column>
                            Process improvement 
                        </Grid.Column>
                        <Grid.Column>
                            Instructional Design
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </Layout>
    ) 
}

export default ContactPage